import React from 'react';
import { Snackbar, Slide, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#e0f7fa', // Light cyan background for a fresh look
    opacity: 0.7,
    color: 'red',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[5],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center items horizontally
    marginTop: '10%', // Set margin top to 10%
  },
  heading: {
    fontSize: '1.5rem', // Larger font size for the heading
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'center', // Center the heading text
  },
  message: {
    fontSize: '1.2rem', // Adjust as needed
    fontWeight: '400',
    textAlign: 'center', // Center the message text
    color:"black"
  },
}));

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function SnackbarComp() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionDown}
      transitionDuration={200}
      key={vertical + horizontal}
    >
      <Alert onClose={handleClose} severity="info" className={classes.root} sx={{ width: '100%' }}>
        <Typography className={classes.heading}>Disclaimer</Typography>
        <Typography className={classes.message}>
          The web tool does not replace the selection process for non-metallic materials as per Shell DEPs.
        </Typography>
      </Alert>
    </Snackbar>
  );
}
