import {
    Box,
    Button,
    CardContent,
    Checkbox,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    Card,
  } from "@material-ui/core";
  import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
  import React, { useEffect, useState } from "react";
  import SolubilityChart from "./SolubilityChart";
  import axios from "../common/AxiosConfig";
  import TableOutput from "../common/TableOutput";
  import { CSVLink } from "react-csv";
  import { useStyles } from "../qualitative compatibility/bubblechart/utils";
  
  const SolubilityParam = () => {
    const classes = useStyles();
    const [solventOptions, setSolventOptions] = useState([]);
    const [solventSelected, setSolventSelected] = useState([]);
    console.log('solventSelected: ', solventSelected);
    const [allHildebrandData, setAllHildebrandData] = useState([]);
    console.log('allHildebrandData: ', allHildebrandData);
    const [polymerOption, setPolymerOption] = useState([]);
    const [polymerSelected, setPolymerSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [showResults, setShowResults] = useState(false); // State to control the visibility of results
    const [filteredHildebrandData, setFilteredHildebrandData] = useState([])
  
    // Fetch polymer options
    useEffect(() => {
      const url = "https://digipol.azurewebsites.net/homopolymers_data_str";
      const token = localStorage.getItem("accessToken");
      setIsLoading(true);
      axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => {
          setPolymerOption(res?.data?.data);
          setIsLoading(false);
        });
    }, []);
  
    // Fetch Hildebrand data
        useEffect(() => {
        if (solventSelected.length > 0) {
            setTableLoading(true);
            const url = "https://digipol.azurewebsites.net/hildebrand_all_data";
            const token = localStorage.getItem("accessToken");
            axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
            .then(res => {
                setAllHildebrandData(res?.data?.data);
                setTableLoading(false);
            });
        }
        }, [solventSelected,polymerSelected]);
  
    // Fetch solvent options
    useEffect(() => {
      const url = "https://digipol.azurewebsites.net/solvents_data";
      const token = localStorage.getItem("accessToken");
      setIsLoading(true);
      axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then(res => {
          setSolventOptions(res?.data?.data);
          setIsLoading(false);
        });
    }, []);
  
    // Handle polymer selection
    const handlePolymer = (e, value) => {
      const filtered = polymerOption.filter(item => value.includes(item.Homopolymer));
      setPolymerSelected(filtered);
      setShowResults(false)

    };
  
    // Handle solvent selection
    const handleSolvent = (e, value) => {
      const filtered = solventOptions.filter(item => value.includes(item.Solvent));
      setShowResults(false)
      setSolventSelected(filtered);
    };
  
    // Handle submission
    const handleSubmit = () => {
      if (solventSelected.length > 0 && polymerSelected.length > 0) {
        // Extract the selected polymer and solvent names
const selectedPolymers = polymerSelected.map(poly => poly.Homopolymer);
console.log('selectedPolymers: ', selectedPolymers);
const selectedSolvents = solventSelected.map(sol => sol.Solvent);

// Filter the data based on the selected names
const filteredData = allHildebrandData?.filter(item => 
    selectedPolymers.includes(item["Solvent/Polymer"]) || 
    selectedSolvents.includes(item["Solvent/Polymer"])
);
setFilteredHildebrandData(filteredData)
console.log('filteredData: ', filteredData);
        setShowResults(true); // Show results on submission
        // Fetch additional data if needed here
      }
    };
  
    // Set table headers
    useEffect(() => {
      if (allHildebrandData.length > 0) {
        const headers = Object.keys(allHildebrandData[0]);
        setTableHeaders(headers);
      }
    }, [allHildebrandData]);
  
    if (isLoading) {
      return (
        <Box sx={{ display: "flex", margin: "50px", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      );
    }
  
    return (
      <div>
        <fieldset style={{ border: "1px solid #ddd", borderRadius: "8px", padding: "16px" }}>
          <legend style={{ fontWeight: "bold", color: "gray" }}>Filter</legend>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                autoComplete="off"
                sx={{ width: "100%" }}
                options={polymerOption.map(x => x.Homopolymer)}
                onChange={handlePolymer}
                renderInput={(params) => <TextField {...params} label="Homopolymer" />}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Autocomplete
                multiple
                autoComplete="off"
                sx={{ width: "100%" }}
                options={solventOptions.map(x => x.Solvent)}
                onChange={handleSolvent}
                renderInput={(params) => <TextField {...params} label="Solvent" />}
              />
            </Grid>
          </Grid>
        </fieldset>
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
          Submit
        </Button>
  
        {showResults && (
          <>
            <Grid container spacing={2} style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <SolubilityChart
                  key={`${solventSelected}-${polymerSelected}`}
                  solventSelected={solventSelected}
                  polymerSelected={polymerSelected}
                />
              </Grid>
            </Grid>
            {filteredHildebrandData.length > 0  &&
       
       <Grid item xs={12}>
         <div style={{ display: "flex", justifyContent: "end" }}>
           <div id='divCSVDownload' style={{ float: 'right', marginTop: "20px" }}>
             <CSVLink filename={"Hansen" + '.csv'} data={filteredHildebrandData} headers={tableHeaders}>
               <Button variant="contained" color="primary">
                 Export Excel
               </Button>
             </CSVLink>
           </div>
         </div>
       </Grid>
      }
      <br/>
      <br/>
            <Grid item xs={12} md={12}>
              {filteredHildebrandData.length > 0 ? (
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <TableOutput TableData={filteredHildebrandData} />
                  </CardContent>
                </Card>
              ) : (
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography>No Data Available</Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </>
        )}
  
      
      </div>
    );
  };
  
  export default SolubilityParam;
  